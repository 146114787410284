<div class="header-container">
	<div class="logo-container">
		<button (click)="toggleSidebar()" mat-icon-button>
			<mat-icon>vertical_split</mat-icon>
		</button>
		<a routerLink="/home">
			<img
				fireCloudAsset
				class="icon ms-3"
				src="assets/admin/logo/fireball_icon.png"
				alt="Fireball Icon"
			/>
			<img
				fireCloudAsset
				class="mx-3 overflow-hidden logo"
				src="assets/admin/logo/fireball-logo.png"
				alt="Fireball Logo"
			/>
		</a>
	</div>
	<mat-slide-toggle
		class="ms-2"
		[checked]="currentTheme === MaterialTheme.DARK"
		(change)="toggleTheme()"
	>
		Dark
	</mat-slide-toggle>
</div>

<div class="header-container">
	<mat-menu #menuUser="matMenu">
		<div class="us-profile">
			<div class="us-profile__img">
				<img
					fireCloudAsset
					[src]="user?.profilePicture ?? 'assets/admin/profile.png'"
					alt="user-photo"
				/>
			</div>
			<div class="us-profile__name mb-2">{{user?.name}}</div>
			<div *ngIf="user?.timezoneName" class="us-profile__timezone">
				{{user.timezoneName}} ({{getOffset()}}) - {{now | tzDate:'HH:mm'}}
			</div>
		</div>

		<div class="us-link">
			<a routerLink="/settings" class="us-link__item" mat-menu-item>
				<div class="us-link__icon">
					<mat-icon aria-hidden="false" aria-label="Settings"
						>settings</mat-icon
					>
				</div>
				<div class="us-link__text">Settings</div>
			</a>
			<a (click)="signOut()" class="us-link__item" mat-menu-item>
				<div class="us-link__icon">
					<mat-icon aria-hidden="false" aria-label="Sign out">logout</mat-icon>
				</div>
				<div class="us-link__text">Sign out</div>
			</a>
		</div>
	</mat-menu>

	<mat-menu #menu="matMenu" class="organization-menu">
		<!-- ORGANIZATIONS ITEMS -->
		<div
			(click)="selectOrganization(userOrganization.organization)"
			*ngFor="let userOrganization of userOrganizations"
			[class.selected]="userOrganization.organization.id === selectedOrganization.id"
			mat-menu-item
			class="menu-wrapper"
		>
			<div
				class="image-wrapper d-flex justify-content-center align-items-center mx-2"
			>
				<img
					class="rounded-circle"
					width="30px"
					height="30px"
					*ngIf="userOrganization.organization.logo; else noOrgLogo"
					fireCloudAsset
					[src]="userOrganization.organization.logo"
				/>

				<ng-template #noOrgLogo>
					<div class="no-logo-wrapper">
						<span
							>{{getOrganizationAbbriviation(userOrganization.organization.name)}}</span
						>
					</div>
				</ng-template>
			</div>

			<div class="menu-item">
				<span
					title="{{userOrganization.organization.name}} ({{userOrganization.organization.organizationTypeName}})"
					class="name"
					>{{userOrganization.organization.name}}
					<span
						*ngIf="userOrganization.organization.organizationTypeName !== OrganizationTypes.FIREBALL"
						>({{userOrganization.organization.organizationTypeName}})</span
					></span
				>

				<div class="roles" [title]="getRolesTitle(userOrganization.roles)">
					<span
						*ngFor="let role of userOrganization.roles; let i = index; let l = count; trackBy:identify"
						>{{l - 1 === i ? role.name : role.name + ", "}}</span
					>
				</div>
			</div>
		</div>
		<!-- ORGANIZATIONS ITEMS -->

		<!-- SETTINGS ITEM -->
		<div class="settings-item">
			<div class="d-flex justify-content-between">
				<button
					routerLink="/settings/organization"
					mat-flat-button
					color="basic"
				>
					Settings
				</button>

				<button
					routerLink="/settings/organization"
					[queryParams]="{ tab: 'billing'}"
					mat-flat-button
					color="basic"
				>
					Billing
				</button>
			</div>
		</div>
		<!-- SETTINGS ITEM -->
	</mat-menu>
	<div
		class="header-org"
		(menuOpened)="organizationMenuOpened()"
		[matMenuTriggerFor]="menu"
	>
		<img
			*ngIf="selectedOrganization?.logo; else noLogo"
			fireCloudAsset
			[src]="selectedOrganization.logo"
			alt="Selected organization logo"
		/>

		<ng-template #noLogo>
			<span>{{getOrganizationAbbriviation(selectedOrganization?.name)}}</span>
		</ng-template>
	</div>
	<div class="header-user" [matMenuTriggerFor]="menuUser">
		<img
			fireCloudAsset
			[src]="user?.profilePicture ?? 'assets/admin/profile.png'"
			alt="user header photo"
		/>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import {
	NbMediaBreakpointsService,
	NbMenuService,
	NbSidebarService,
	NbThemeService,
} from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { User, UserOrganization } from '@core/models/entities/User';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { LayoutService } from '@core/utils/layout.service';
import { RippleService } from '@core/utils/ripple.service';
import {
	OrganizationService,
	OrganizationTypes,
} from '@core/services/organization.service';
import { Organization } from '@core/models/entities/Organization';
import { Role } from '@core/models/entities/Role';
import { TimezoneSelectComponent } from '@shared/components/selectors/timezone-select/timezone-select.component';

export enum MaterialTheme {
	'DARK' = 'Dark',
	'LIGHT' = 'Light',
}

@Component({
	selector: 'fire-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	private _currentTheme: MaterialTheme;

	userPictureOnly = false;

	readonly userMenuTag = 'user-menu';

	readonly userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

	get userOrganizations(): UserOrganization[] {
		return this._orgService.userOrganizations?.sort((a) =>
			a.organization.organizationTypeName === OrganizationTypes.FIREBALL
				? -1
				: 1,
		);
	}

	get selectedOrganization(): Organization {
		return this._orgService.selectedOrganization;
	}

	get now(): Date {
		return new Date();
	}

	get user(): Partial<User> {
		return this._orgService.user;
	}

	get MaterialTheme(): typeof MaterialTheme {
		return MaterialTheme;
	}

	get currentTheme(): MaterialTheme {
		return this._currentTheme;
	}

	set currentTheme(theme: MaterialTheme) {
		this._currentTheme = theme;

		this.userPreferedTheme = theme;

		if (theme === MaterialTheme.LIGHT) {
			this._themeService.changeTheme('material-light');
		} else {
			this._themeService.changeTheme('material-dark');
		}
	}

	get userPreferedTheme(): MaterialTheme {
		return localStorage.getItem('preferredTheme') as MaterialTheme;
	}

	set userPreferedTheme(theme: MaterialTheme) {
		localStorage.setItem('preferredTheme', theme);
	}

	identify(index: number, item: Role): string {
		return item.id;
	}

	readonly OrganizationTypes = OrganizationTypes;

	constructor(
		private _sidebarService: NbSidebarService,
		private _orgService: OrganizationService,
		private _menuService: NbMenuService,
		private _themeService: NbThemeService,
		private _layoutService: LayoutService,
		private _breakpointService: NbMediaBreakpointsService,
		private _rippleService: RippleService,
		private _nbMenuService: NbMenuService,
		private _router: Router,
		private _authService: AuthService,
	) {
		// Set the default theme
		if (this.userPreferedTheme) {
			this.currentTheme = this.userPreferedTheme;
		} else {
			this.currentTheme = MaterialTheme.LIGHT;
		}

		this._rippleService.toggle(true);
	}

	ngOnInit(): void {
		this._trackUserMenu();

		this._userMenuClickHandler();
	}

	getOrganizationAbbriviation(name: string): string {
		if (!name) {
			return '';
		}

		if (name.split(' ').length > 1) {
			return name
				.split(' ')
				.map((n) => n.substring(0, 1))
				.join('')
				.toUpperCase();
		} else {
			return name.substring(0, 3).toUpperCase();
		}
	}

	/** This fixed a bug where the "multiple" class cannot be added with property binding */
	organizationMenuOpened(): void {
		const orgMenu = document.getElementsByClassName(
			'organization-menu',
		)[0] as HTMLDivElement;

		if (this.userOrganizations.length > 1) {
			orgMenu.classList.add('multiple');
		}
	}

	getOffset(): string {
		return TimezoneSelectComponent.getOffset(this.user?.timezoneName);
	}

	getRolesTitle(roles: Role[]): string {
		return roles
			.map((r) => r.name)
			.join(', ')
			.replace(/, ([^,]*)$/, ' and $1');
	}

	selectOrganization(organization: Organization): void {
		if (organization.id === this.selectedOrganization.id) {
			return;
		}

		this._orgService.select(organization.id).subscribe(() => {
			this._orgService.userOrganizationChanged$.next(organization);
		});
	}

	toggleTheme(): void {
		this.currentTheme === MaterialTheme.DARK
			? (this.currentTheme = MaterialTheme.LIGHT)
			: (this.currentTheme = MaterialTheme.DARK);
	}

	toggleSidebar(): boolean {
		this._sidebarService.toggle(true, 'menu-sidebar');

		this._layoutService.changeLayoutSize();

		return false;
	}

	navigateHome(): boolean {
		this._menuService.navigateHome();

		return false;
	}

	signOut(): void {
		this._authService.signOut();
	}

	private _userMenuClickHandler(): void {
		this._nbMenuService
			.onItemClick()
			.pipe(
				filter(({ tag }) => tag === this.userMenuTag),
				map(({ item: { title } }) => title),
			)
			.subscribe((title) => {
				switch (title) {
					case 'Profile':
						this._router.navigate(['settings/profile']);

						break;
					case 'Log out':
						this._authService.signOut();

						break;
				}
			});
	}

	private _trackUserMenu(): void {
		const { xl } = this._breakpointService.getBreakpointsMap();

		this._themeService
			.onMediaQueryChange()
			.pipe(map(([, currentBreakpoint]) => currentBreakpoint.width < xl))
			.subscribe(
				(isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
			);
	}
}
